import { PaymentPayloadFields } from '@constants/payment/paymentPayloadFields'

export enum PaymentFormFields {
  ADDRESS = PaymentPayloadFields.ADDRESS,
  ADMINISTRATIVE_AREA = PaymentPayloadFields.ADMINISTRATIVE_AREA,
  BILLING_ADDRESS = 'billingAddress',
  CARD_EXPIRATION_DATE = 'cardExpirationDate',
  CARD_NUMBER = PaymentPayloadFields.CARD_NUMBER,
  CARD_TYPE = PaymentPayloadFields.CARD_TYPE,
  CITY = PaymentPayloadFields.CITY,
  CONFIRM_EMAIL = 'confirmEmail',
  COUNTRY = PaymentPayloadFields.COUNTRY,
  EMAIL = PaymentPayloadFields.EMAIL,
  NAME_ON_CARD = PaymentPayloadFields.NAME_ON_CARD,
  NONCE = PaymentPayloadFields.NONCE,
  PAYMENT_METHOD = PaymentPayloadFields.PAYMENT_METHOD,
  PHONE_NUMBER = PaymentPayloadFields.PHONE_NUMBER,
  POSTAL_CODE = PaymentPayloadFields.POSTAL_CODE,
  RETURN_URL = PaymentPayloadFields.RETURN_URL,
  SCREEN_HEIGHT = 'screen_height',
  SCREEN_WIDTH = 'screen_width',
  SECRET_CODE = PaymentPayloadFields.SECRET_CODE,
  TERMS_AND_CONDITION = 'termsAndConditions',
  VOUCHER = 'voucher',
}

export enum PaymentFormMultiFields {
  CARD_INFO = 'cardInfo',
}
