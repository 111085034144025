import { aiSearchWidgetUtils } from '@aiSearchWidget/utils/common'
import getAppConfig from '@services/config/config'

const { publicRuntimeConfig } = getAppConfig()

export const baseUrl = aiSearchWidgetUtils.getDomain() || publicRuntimeConfig.backendApiUrl
export const additionalBaseUrl = publicRuntimeConfig.additionalBackendApiUrl || baseUrl

export const tildaPageUrl = 'https://api.tildacdn.info'

export const urlVersionPrefix = '/api/v1/'
export const urlVersionPrefixV2 = '/api/v2/'
export const urlVersionPrefixV3 = '/api/v3/'
export const externalApiPrefix = urlVersionPrefix + 'external-booking'
export const externalApiPrefixV2 = urlVersionPrefixV2 + 'external-booking'
