import { urlVersionPrefixV2, urlVersionPrefixV3 } from '@constants/api/api'

export default {
  // /api/v3/internal/train/booking/{hash}/payment
  bookingPayment: (hash: string) => `${urlVersionPrefixV3}internal/train/booking/${hash}/payment`,

  // /api/v3/internal/train/booking/{hash}/payment-method
  bookingPaymentMethods: (hash: string) => `${urlVersionPrefixV3}internal/train/booking/${hash}/payment-method`,

  // /api/v2/payment/ecommpay-apple-pay/session
  ecommpayAppleSession: `${urlVersionPrefixV2}payment/ecommpay-apple-pay/session`,
}
